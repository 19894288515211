// @import "./assets/scss/vars.scss";

// $theme-colors: (
//   primary: $bitte-green,
//   // secondary: $glowplug-secondary,
//   // success: $glowplug-success,
//   // info: $glowplug-info,
//   // warning: $glowplug-warning,
//   // danger: $glowplug-danger,
//   // light: $glowplug-light,
//   // dark: $glowplug-dark,
//   // border: $glowplug-border,
//   // reward: $glowplug-gold,,,,,,,,,,,,,,,,,,,,,,,,
// );

@import "./assets/scss/shuffle/bootstrap-theme.scss";

// html,
// body,
// #root {
//   height: 100%;
//   width: 100%;
//   margin: 0;
//   padding: 0;
//   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
//     Helvetica, Arial, "Lucida Grande", sans-serif;
//   font-weight: 300;
//   background: $default-background;
//   color: $bitte-text;
// }

// .hero {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   height: 100%;
//   min-height: 80vh;
// }

// .app-wrapper {
//   padding-top: 1rem;
// }

// .home-screenshot {
//   max-width: 280px;
//   border: 2px solid $bitte-text-alt;
//   border-radius: 1.5rem;
//   box-shadow: $default-shadow;
//   margin: auto;
//   margin: 1rem;
// }

// .navbar {
//   background-color: $bitte-text-alt;
// }

// .home-section {
//   margin-bottom: 3rem;
// }

// .logo {
//   width: 400px;
//   margin: auto;
//   border-radius: 4rem;
// }
