$enable-shadows: true;
$enable-gradients: false;
$primary: #18e4ab;
$secondary: #34535a;
$body-bg: #192629;
$white: #fff;
$body-color: $white;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$text-muted: $gray-200;
$font-family-sans-serif: "HelveticaNeue-Light", "Helvetica Neue Light",
  "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$navbar-dark-active-color: $primary;
// Colors / Gradients from Xcode
$default-background: #192629;
$default-gradient: linear-gradient(145deg, #1b292c, #172225);
$default-shadow: 10px 10px 30px #121b1e, -10px -10px 30px #1b292c;
$default-shadow-hovered: 5px 5px 15px #121b1e, -5px -5px 15px #1b292c;
$default-transition: 125ms all ease-in-out;

$bitte-green: #18e4ab;
$bitte-pink: #fd79a8;
$bitte-yellow: #fdcb6e;

$bitte-text: #6997a3;
$bitte-text-alt: #34535a;

$spotify-green: #1db954;
$apple-music-red: #fc9aa4;
