/*!
* Bootstrap v5.0.0-alpha (https://getbootstrap.com/)
* Copyright 2011-2020 The Bootstrap Authors
* Copyright 2011-2020 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/

/*! Theme created with the Bootstrap Shuffle (https://bootstrapshuffle.com) */

// Required
@import "../bootstrap/functions";
@import "../bootstrap/mixins";
// Your variables
@import "custom-variables";
// Required Bootstrap variables
@import "../bootstrap/variables";
// Utilities
@import "../bootstrap/utilities";

// Layout & components
@import "../bootstrap/root";
@import "../bootstrap/reboot";
@import "../bootstrap/type";
@import "../bootstrap/images";
@import "../bootstrap/containers";
@import "../bootstrap/grid";
// @import "../bootstrap/tables";
// @import "../bootstrap/forms";
@import "../bootstrap/buttons";
@import "../bootstrap/transitions";
// @import "../bootstrap/dropdown";
@import "../bootstrap/button-group";
@import "../bootstrap/nav";
@import "../bootstrap/navbar";
@import "../bootstrap/card";
// @import "../bootstrap/accordion";
// @import "../bootstrap/breadcrumb";
// @import "../bootstrap/pagination";
@import "../bootstrap/badge";
// @import "../bootstrap/alert";
// @import "../bootstrap/progress";
// @import "../bootstrap/list-group";
// @import "../bootstrap/close";
// @import "../bootstrap/toasts";
// @import "../bootstrap/modal";
// @import "../bootstrap/tooltip";
// @import "../bootstrap/popover";
// @import "../bootstrap/carousel";
// @import "../bootstrap/spinners";

// Helpers
@import "../bootstrap/helpers";

// Utilities
@import "../bootstrap/utilities/api";
// Your styles
@import "custom-styles";
