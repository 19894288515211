.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    max-width: 60px;
    animation: rotate 1.5s linear infinite;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
