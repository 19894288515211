body {
  font-size: 1.2rem;
}

hr {
  background-color: $primary;
}

// a[target="_blank"] {
//   &:after {
//     content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
//     margin: 0 3px 0 5px;
//   }
// }
.navbar {
  z-index: 1;
}
.appstore-badge {
  width: 240px;
}
.appstore-fun {
  width: 300px;
}
.bitte-nav {
  background-color: #34535a;
  z-index: 1;
}
.bitte-logo {
  max-width: 32px;
  border-radius: 1rem;
  margin-right: 0.5rem;
}
.card-body {
  z-index: 1;
}

.badge {
  padding: 0.25rem 0.35rem;
  border-radius: 3rem;
  color: $dark;

  &.spotify {
    background-color: $spotify-green;
  }
  &.appleMusic {
    background-color: $apple-music-red;
  }
  &.publicOrPrivate {
    background-color: $bitte-yellow;
  }
  &.hosted {
    background-color: $bitte-green;
  }
  &.attended {
    background-color: $bitte-pink;
  }
  &.bitte {
    background-color: $bitte-green;
  }
  &.privacy {
    background-color: #e74c3c;
    color: white;
  }
  &.iap {
    background-color: #8e44ad;
    color: white;
  }
}

.home-callout-number {
  background-color: $bitte-green;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark;
  padding: 0;
  margin: 0;
  line-height: 0;

  margin-right: 1rem;
}

.map-pad {
  padding-top: 400px;
}

.spotify-green {
  color: $spotify-green;
}
.apple-music-red {
  color: $apple-music-red;
}

.help-section-image-wrapper {
  background-color: $bitte-text;
  border-radius: 0.5rem;
  padding: 0.5rem;
  max-width: 300px;
  margin: auto;
  margin-bottom: 1rem;
  p {
    color: $white;
    text-align: center;
    font-style: italic;
    font-size: 0.8rem;
    margin: 0;
  }
}
.help-section-image {
  max-width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}
